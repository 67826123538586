import BCP47 from '@repo/types/locale/BCP47'
import ISO3166_1 from '@repo/types/locale/ISO3166_1'

/* istanbul ignore next */
namespace LTILocale {
    export const English = BCP47.English
    export type English = BCP47.English

    export const German = BCP47.German
    export type German = BCP47.German

    export const Greek = BCP47.Greek
    export type Greek = BCP47.Greek

    export const French = BCP47.French
    export type French = BCP47.French

    export const Portuguese = BCP47.Portuguese
    export type Portuguese = BCP47.Portuguese

    export const Spanish = BCP47.Spanish
    export type Spanish = BCP47.Spanish

    export const All = [English, German, Greek, French, Portuguese, Spanish] as const
    export type All = typeof All

    export function is(locale: unknown): locale is LTILocale {
        switch (locale) {
            case English:
            case French:
            case German:
            case Greek:
            case Portuguese:
            case Spanish:
                return true
            default:
                return false
        }
    }

    export function Native(locale: English): Native.English
    export function Native(locale: French): Native.French
    export function Native(locale: German): Native.German
    export function Native(locale: Greek): Native.Greek
    export function Native(locale: Portuguese): Native.Portuguese
    export function Native(locale: Spanish): Native.Spanish
    export function Native(locale: LTILocale): Native
    export function Native(locale: BCP47): Native | undefined
    export function Native(locale: Exclude<unknown, BCP47>): undefined
    export function Native(locale: unknown): Native | undefined
    export function Native(locale: unknown): Native | undefined {
        switch (locale) {
            case English:
                return Native.English
            case French:
                return Native.French
            case German:
                return Native.German
            case Greek:
                return Native.Greek
            case Portuguese:
                return Native.Portuguese
            case Spanish:
                return Native.Spanish
            default:
                return undefined
        }
    }

    /* istanbul ignore next */
    export namespace Native {
        export const English = BCP47.Native.English
        export type English = BCP47.Native.English

        export const German = BCP47.Native.German
        export type German = BCP47.Native.German

        export const Greek = BCP47.Native.Greek
        export type Greek = BCP47.Native.Greek

        export const French = BCP47.Native.French
        export type French = BCP47.Native.French

        export const Portuguese = BCP47.Native.Portuguese
        export type Portuguese = BCP47.Native.Portuguese

        export const Spanish = BCP47.Native.Spanish
        export type Spanish = BCP47.Native.Spanish

        export const All = [English, German, Greek, French, Portuguese, Spanish] as const
        export type All = typeof All

        export function is(locale: unknown): locale is Native {
            switch (locale) {
                case English:
                case Native.French:
                case Native.German:
                case Native.Greek:
                case Native.Portuguese:
                case Native.Spanish:
                    return true
                default:
                    return false
            }
        }
    }

    // biome-ignore lint/suspicious/noRedeclare:
    export type Native = (typeof Native)[Exclude<keyof typeof Native, 'is' | 'All'>]

    /* istanbul ignore next */
    export namespace Translation {
        export const English = BCP47.Translation.English
        export type English = BCP47.Translation.English

        export const German = BCP47.Translation.German
        export type German = BCP47.Translation.German

        export const Greek = BCP47.Translation.Greek
        export type Greek = BCP47.Translation.Greek

        export const French = BCP47.Translation.French
        export type French = BCP47.Translation.French

        export const Portuguese = BCP47.Translation.Portuguese
        export type Portuguese = BCP47.Translation.Portuguese

        export const Spanish = BCP47.Translation.Spanish
        export type Spanish = BCP47.Translation.Spanish
    }

    export type Translation = (typeof Translation)[keyof typeof Translation]

    export function ISO(locale: English): ISO.English
    export function ISO(locale: French): ISO.French
    export function ISO(locale: German): ISO.German
    export function ISO(locale: Greek): ISO.Greek
    export function ISO(locale: Portuguese): ISO.Portuguese
    export function ISO(locale: Spanish): ISO.Spanish
    export function ISO(locale: LTILocale): ISO3166_1
    export function ISO(locale: Exclude<unknown, ISO3166_1>): undefined
    export function ISO(locale: unknown): ISO3166_1 | undefined {
        switch (locale) {
            case English:
                return ISO.English
            case German:
                return ISO.German
            case French:
                return ISO.French
            case Greek:
                return ISO.Greek
            case Portuguese:
                return ISO.Portuguese
            case Spanish:
                return ISO.Spanish
            default:
                return undefined
        }
    }

    /* istanbul ignore next */
    export namespace ISO {
        export const English = ISO3166_1.UK
        export type English = ISO3166_1.UK

        export const German = ISO3166_1.Germany
        export type German = ISO3166_1.Germany

        export const French = ISO3166_1.France
        export type French = ISO3166_1.France

        export const Greek = ISO3166_1.Greece
        export type Greek = ISO3166_1.Greece

        export const Portuguese = ISO3166_1.Portugal
        export type Portuguese = ISO3166_1.Portugal

        export const Spanish = ISO3166_1.Spain
        export type Spanish = ISO3166_1.Spain

        export const All = [English, German, Greek, French, Portuguese, Spanish] as const
        export type All = typeof All

        export function is(locale: unknown): locale is LTILocale {
            switch (locale) {
                case ISO3166_1.UK:
                case ISO3166_1.France:
                case ISO3166_1.Germany:
                case ISO3166_1.Greece:
                case ISO3166_1.Portugal:
                case ISO3166_1.Spain:
                    return true
                default:
                    return false
            }
        }
    }

    // biome-ignore lint/suspicious/noRedeclare:
    export type ISO = (typeof ISO)[Exclude<keyof typeof ISO, 'is' | 'All'>]
}

type LTILocale = (typeof LTILocale)[Exclude<keyof typeof LTILocale, 'is' | 'Native' | 'Translation' | 'ISO' | 'All'>]

export const LTILocaleNative = LTILocale.Native
export type LTILocaleNative = typeof LTILocale.Native

export default LTILocale
