import type Some from '@repo/types/array/Some'
import BCP47 from '@repo/types/locale/BCP47'
import LTILocale from '@repo/types/locale/LTILocale'
import type Natural from '@repo/types/number/Natural'
import type Range from '@repo/types/number/Range'
import _JSONSchema, { AddErrorMessages, Partialize, PushErrors } from '@repo/types/schema/JSONSchema'
import _Metadata from '@repo/types/util/Metadata'
import UserID from '@repo/types/util/id/UserID'
import Ajv from 'ajv'
import ajv_errors from 'ajv-errors'
import addFormats from 'ajv-formats'
// @ts-ignore
import { toASCII } from 'punycode/'
import { z } from 'zod'

namespace User {
    export const ID = UserID
    export type ID = UserID

    export namespace Image {
        export namespace Size {
            export const Min = 0 as const
            export type Min = typeof Min

            export const Max = 262144 as const // 256 * 1024 = 256 KiB
            export type Max = typeof Max
        }

        export type Size = Natural

        export namespace MIMEType {
            export const JPEG = 'image/jpeg' as const
            export type JPEG = typeof JPEG

            export const PNG = 'image/png' as const
            export type PNG = typeof PNG

            export const GIF = 'image/gif' as const
            export type GIF = typeof GIF
        }

        export type MIMEType = (typeof MIMEType)[keyof typeof MIMEType]

        const MIME_TYPE_ARR = Object.values(MIMEType) as Readonly<string[]>

        export namespace Blob {
            export function IS_IMPL({ type, size }: globalThis.Blob) {
                if (!MIME_TYPE_ARR.includes(type)) return false
                return size >= Size.Min && size <= Size.Max
            }

            export function is(image: boolean | string | number | bigint | null | undefined): false
            export function is(image: object): boolean
            export function is(image: (typeof globalThis)['Blob']): boolean
            export function is(image: Blob): true
            export function is(image: unknown): image is Image {
                if (typeof image !== 'object') return false
                if (image === null) return false
                if (!(image instanceof globalThis.Blob)) return false
                return IS_IMPL(image)
            }
        }

        export type Blob = (typeof globalThis)['Blob'] & { type: Image.MIMEType }

        export namespace DataURL {
            export function IS_IMPL(dataurl: string) {
                // https://github.com/validatorjs/validator.js/blob/master/src/lib/isDataURI.js

                const [attrStr, ...data] = dataurl.split(',')
                if (attrStr === undefined) return false

                const [schemeMediaType, ...attr] = attrStr.trim().split(';')
                if (schemeMediaType?.slice(0, 5) !== 'data:') return false

                const mediaType = schemeMediaType.slice(5)
                if (!(MIME_TYPE_ARR as Readonly<string[]>).includes(mediaType)) return false

                for (let i = 0; i < attr.length; i++) {
                    if (
                        !(i === attr.length - 1 && attr[i]!.toLowerCase() === 'base64') &&
                        !/^[a-z\-]+=[a-z0-9\-]+$/i.test(attr[i]!)
                    )
                        return false
                }

                let size = 0
                for (let i = 0; i < data.length; size += data[i++]!.length) {
                    if (!/^[a-z0-9!\$&'\(\)\*\+,;=\-\._~:@\/\?%\s]*$/i.test(data[i]!)) return false
                }

                return size >= Image.Size.Min && size <= Image.Size.Max
            }

            export function is(image: boolean | object | number | bigint | null | undefined): false
            export function is(image: string): boolean
            export function is(image: DataURL): true
            export function is(image: unknown): image is Image {
                if (typeof image !== 'string') return false
                return IS_IMPL(image)
            }
        }

        export type DataURL = `data:${MIMEType};base64,${string}`
    }

    export type Image = Image.Blob | Image.DataURL

    export namespace Name {
        export namespace Length {
            export const Min = 1 as const
            export type Min = typeof Min

            export const Max = 50 as const
            export type Max = typeof Max
        }

        export function is(name: object | number | bigint | boolean | null | undefined): false
        export function is(name: string): name is Name & { length: Length }
        export function is(name: string | null | undefined): name is Name & { length: Length }
        export function is(name: Name & { length: Length }): true
        export function is(name: unknown): name is Name & { length: Length }
        export function is(name: unknown): name is Name & { length: Length } {
            if (typeof name !== 'string') return false
            if (name.length < Length.Min) return false
            if (name.length > Length.Max) return false
            return true
        }

        export function normalize(name: object | number | bigint | boolean | null | undefined): undefined
        export function normalize(name: string): Name | undefined
        export function normalize(name: string | null | undefined): Name | undefined
        export function normalize(name: Name & { length: Length }): Name
        export function normalize(name: unknown): unknown {
            if (typeof name !== 'string') return undefined

            name = name.trim()
            if (!is(name)) return undefined
            return name.trim()
        }

        export type Length = Range<Length.Min, Length.Max>
    }

    export type Name = string

    export namespace Email {
        export namespace Local {
            export const Regex =
                /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~\-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*/

            const REGEX_IMPL = new RegExp(`^${Regex.source.replace(/(\\\\)/g, '\\')}$`)
            export function is(local: unknown): local is Local {
                if (typeof local !== 'string') return false
                return REGEX_IMPL.test(local)
            }

            export function normalize(local: Local, domain?: Domain | undefined, locale?: BCP47): Local
            export function normalize(local: unknown, domain?: Domain | undefined, locale?: BCP47): Local | undefined
            export function normalize(
                local: unknown,
                domain: Domain | undefined = undefined,
                locale: BCP47 = BCP47.English
            ): Local | undefined {
                if (typeof local !== 'string') return undefined

                let str = toASCII(local).toLocaleLowerCase(locale)

                if (!is(str)) return undefined
                str = local

                domain = Domain.normalize(domain, locale)

                let exec = /^\(.*\)(.+)$/.exec(str)
                if (exec?.[1]) str = exec[1]

                exec = /^(.+)\(.*\)$/.exec(str)
                if (exec?.[1]) str = exec[1]

                switch (domain) {
                    // + and .
                    case 'googlemail.com': //fals through
                    case 'gmail.com': // falls through
                    // biome-ignore lint/suspicious/noFallthroughSwitchClause: falls through
                    case 'live.com':
                        str = str.replaceAll('.', '')

                    // + only
                    // biome-ignore lint/suspicious/noFallthroughSwitchClause: falls through
                    case 'hotmail.com': {
                        const exec = /^(.*)\+.*$/.exec(str)
                        if (exec?.[1]) str = exec[1]
                    }
                    case 'outlook.com':
                        return str.toLocaleLowerCase(locale)

                    default:
                        return str
                }
            }

            export function get(local: Data, locale?: BCP47 | undefined): Local | undefined
            export function get(local: Email, locale?: BCP47): Local
            export function get(local: Local, locale?: BCP47): Local
            export function get(local: unknown, locale?: BCP47 | undefined): Local | undefined
            export function get(local: unknown, locale: BCP47 | undefined = undefined): Local | undefined {
                if (local === null || local === undefined) return undefined
                if (typeof local === 'object') {
                    if ('locale' in local) ({ locale } = local as { locale?: BCP47 })
                    if ('email' in local) local = local.email
                }

                if (typeof local !== 'string') return undefined

                if (!BCP47.is(locale)) locale = BCP47.English

                let domain: Domain | undefined = undefined

                const exec = /(.+)@(.+)/.exec(local)
                if (exec?.[1] && exec?.[2]) {
                    local = exec[1]
                    domain = exec[2]
                }

                return normalize(local, domain, locale)
            }
        }

        export type Local = string

        export namespace Domain {
            const map: Partial<Record<BCP47, Record<string, RegExp>>> = {}

            export function Regex(
                domain: Some<string | RegExp> | undefined = undefined,
                locale: BCP47 = BCP47.English
            ): RegExp {
                if (domain === undefined) return Regex.Global

                if (!Array.isArray(domain)) domain = [domain]
                domain = [...domain]

                const { length } = domain
                for (let i = 0; i < length; i++) {
                    const d = domain[i]

                    if (typeof d !== 'string') {
                        if (typeof d === 'object' && d instanceof RegExp) {
                            domain[i] = `(?:${d.source.replace(/^\^(.)*\$$/, '$1')})`
                            continue
                        }

                        throw new TypeError('string or regular expression expected')
                    }

                    const puny = toASCII(d).toLocaleLowerCase(locale)
                    if (d !== puny) {
                        if (!Regex.Global.test(puny)) {
                            throw new Error(`${puny} is not a valid email domain.`)
                        }

                        domain.push(puny)
                        continue
                    }

                    if (!Regex.Global.test(d)) {
                        throw new Error(`${d} is not a valid email domain.`)
                    }
                }

                domain = domain.map(x => `(?:${new RegExp(x).source.replace(/^\^(.)*\$$/, '$1')})`)
                    ; (domain as string[]).sort((a, b) => a.localeCompare(b, locale))
                domain = domain.join('|')

                let exp = map[locale]?.[domain]
                if (exp) return exp

                exp = new RegExp(`(?=(?:${Regex.Global.source.replace(/(\\\\)/g, '\\')}))${domain}`)

                map[locale] ??= {}
                map[locale]![domain] = exp
                return exp
            }

            export namespace Regex {
                export const Global =
                    /(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])+)/
            }

            export function is(domain: unknown, locale: BCP47 = BCP47.English): domain is Domain {
                if (typeof domain !== 'string') return false

                const { source } = Regex(domain, locale)
                return new RegExp(`^${source}$`).test(domain)
            }

            export function normalize(domain: Domain, locale?: BCP47): Domain
            export function normalize(domain: unknown, locale?: BCP47): Domain | undefined
            export function normalize(domain: unknown, locale: BCP47 = BCP47.English): Domain | undefined {
                if (typeof domain !== 'string') return undefined

                let str = toASCII(domain).toLocaleLowerCase(locale)

                if (!is(str, locale)) return undefined

                let exec = /^\(.*\)(.+)$/.exec(str)
                if (exec?.[1]) str = exec[1]

                exec = /^(.+)\(.*\)$/.exec(str)
                if (exec?.[1]) str = exec[1]

                if (str === 'googlemail.com') str = 'gmail.com'

                return str
            }

            export function get(domain: Data, locale?: BCP47 | undefined): Domain | undefined
            export function get(domain: Email, locale?: BCP47): Domain
            export function get(domain: Domain, locale?: BCP47): Domain
            export function get(domain: unknown, locale?: BCP47 | undefined): Domain | undefined
            export function get(domain: unknown, locale: BCP47 | undefined = undefined): Domain | undefined {
                if (domain === null || domain === undefined) return undefined
                if (typeof domain === 'object') {
                    if ('locale' in domain) ({ locale } = domain as { locale?: BCP47 })
                    if ('email' in domain) domain = domain.email
                }

                if (typeof domain !== 'string') return undefined

                if (!BCP47.is(locale)) locale = BCP47.English

                const res = /.+@(.+)/.exec(domain)
                if (res?.[1]) {
                    domain = res[1]
                }

                return normalize(domain, locale)
            }
        }

        export type Domain = string

        export function Regex(domain: Some<string | RegExp> | undefined = undefined, locale: BCP47 = BCP47.English) {
            if (domain === undefined) return Regex.Global
            return new RegExp(
                `(${Local.Regex.source.replace(/(\\\\)/g, '\\')})@(${Domain.Regex(domain, locale).source.replace(/(\\\\)/g, '\\')})`
            )
        }

        export namespace Regex {
            export const Global = new RegExp(
                `(${Local.Regex.source.replace(/(\\\\)/g, '\\')})@(${Domain.Regex.Global.source.replace(/(\\\\)/g, '\\')})`
            )
        }

        export function is(
            str: unknown,
            domain: Some<string | RegExp> | undefined = undefined,
            locale: BCP47 = BCP47.English
        ): str is Email {
            if (typeof str !== 'string') return false

            const { source } = Regex(domain, locale)
            return new RegExp(`^${source}$`).test(str)
        }

        export function normalize(email: Email, locale?: BCP47): Email
        export function normalize(email: unknown, locale: BCP47 = BCP47.English): Email | undefined {
            const domain = Domain.get(email, locale)
            if (!domain) return undefined

            const local = Local.get(email, locale)
            if (!local) return undefined

            return `${local}@${domain}`
        }

        export function get(
            email: Data,
            domain?: Some<string | RegExp> | undefined,
            locale?: BCP47 | undefined
        ): Email | undefined
        export function get(email: Email, domain: Some<string | RegExp> | undefined, locale: BCP47): Email
        export function get(
            email: unknown,
            domain?: Some<string | RegExp> | undefined,
            locale?: BCP47 | undefined
        ): Email | undefined
        export function get(
            email: unknown,
            domain: Some<string | RegExp> | undefined = undefined,
            locale: BCP47 | undefined = undefined
        ): Email | undefined {
            if (!email) return undefined
            if (typeof email === 'object') {
                ; ({ email, locale } = email as { email: Email; locale: BCP47 })
            }

            if (typeof email !== 'string') return undefined

            if (!BCP47.is(locale)) locale = BCP47.English

            if (domain && !is(email, domain, locale)) return undefined

            return normalize(email as Email, locale)
        }
    }

    // export type Email = `${Email.Local}@${Email.Domain}`
    export type Email = string

    export namespace Role {
        export const Administrator = 'urn:lti:role:ims/lis/Administrator' as const
        export type Administrator = typeof Administrator

        export const Teacher = 'urn:lti:role:ims/lis/Mentor' as const
        export type Teacher = typeof Teacher

        export const Student = 'urn:lti:role:ims/lis/Learner' as const
        export type Student = typeof Student

        export const All = [Administrator, Teacher, Student] as const
        export type All = typeof All

        export enum Relationship {
            Lesser = -1,
            Same = 0,
            Greater = 1,
        }

        export function is(
            candidate:
                | (Omit<Partial<User.Data>, 'id'> & { _id: User.ID })
                | Partial<User.Data>
                | { role: Data['role'];[x: string | number | symbol]: unknown }
                | Some<Role>
                | undefined,
            role: Role
        ) {
            for (const r of get(candidate)) {
                switch (r) {
                    case Administrator:
                        return true

                    case Teacher: {
                        switch (role) {
                            case Administrator:
                                continue
                            default:
                                return true
                        }
                    }

                    case Student: {
                        switch (role) {
                            case Student:
                                return true
                            default:
                                continue
                        }
                    }
                }
            }

            return false
        }

        export namespace is {
            export function Administrator(candidate: Parameters<typeof is>[0]) {
                return is(candidate, Role.Administrator)
            }

            export function Teacher(candidate: Parameters<typeof is>[0]) {
                return is(candidate, Role.Teacher)
            }

            export function Student(candidate: Parameters<typeof is>[0]) {
                return is(candidate, Role.Student)
            }
        }

        export function get(role: Parameters<typeof is>[0]): Role[] {
            if (role === null || role === undefined) return []
            if (typeof role === 'object' && 'role' in role) ({ role } = role as { role: Some<Role> })

            if (!role) return []
            if (!Array.isArray(role)) role = [role] as Role[]

            role = role.filter((role: unknown) => {
                switch (role) {
                    case Administrator: // falls through
                    case Teacher: // falls through
                    case Student:
                        return true
                }

                return false
            })

            role.sort((a, b) => {
                switch (a) {
                    case Administrator:
                        switch (b) {
                            case Administrator:
                                return Relationship.Same
                            default:
                                return Relationship.Greater
                        }

                    case Teacher:
                        switch (b) {
                            case Administrator:
                                return Relationship.Lesser
                            case Teacher:
                                return Relationship.Same
                            case Student:
                                return Relationship.Greater
                            default:
                                return Relationship.Greater
                        }

                    case Student:
                        switch (b) {
                            case Student:
                                return Relationship.Same
                            default:
                                return Relationship.Lesser
                        }
                }
            })

            return role
        }

        export namespace get {
            export function highest(role: Data): Role | undefined
            export function highest(role: Some<Role>): Role
            export function highest(role: Data | Some<Role> | undefined): Role | undefined {
                return get(role)[0]
            }
        }

        export function compare(x: Parameters<typeof is>[0], y: Parameters<typeof is>[0]) {
            x = Role.get(x)[0]
            y = Role.get(y)[0]

            switch (x) {
                case Administrator:
                    switch (y) {
                        case Administrator:
                            return Relationship.Same
                        default:
                            return Relationship.Greater
                    }

                case Teacher:
                    switch (y) {
                        case Administrator:
                            return Relationship.Lesser
                        case Teacher:
                            return Relationship.Same
                        default:
                            return Relationship.Greater
                    }

                case Student:
                    switch (y) {
                        case Student:
                            return Relationship.Same
                        case undefined:
                            return Relationship.Greater
                        default:
                            return Relationship.Lesser
                    }

                case undefined:
                    switch (y) {
                        case undefined:
                            return Relationship.Same
                        default:
                            return Relationship.Lesser
                    }
            }
        }
    }

    export type Role = Role.Administrator | Role.Teacher | Role.Student

    export const Locale = LTILocale
    export type Locale = LTILocale

    export namespace Password {
        export namespace Size {
            export const Min = 0 as const
            export type Min = typeof Min

            export const Max = 72 as const
            export type Max = typeof Max
        }

        export type Size = Range<Size.Min, Size.Max>

        export function IS_IMPL(passwd: string): passwd is Password {
            const { size } = new Blob([passwd])
            return size >= Size.Min && size <= Size.Max
        }

        export function is(passwd: unknown): passwd is Password {
            if (typeof passwd !== 'string') return false
            return IS_IMPL(passwd)
        }

        export namespace Salted {
            export const Regex = /^\$2[abxy]\$((0[4-9])|([1-2][0-9])|30|31)\$[./A-Za-z0-9]{22}([./A-Za-z0-9]{31})?$/

            export function IS_IMPL(passwd: string): passwd is Salted {
                return Regex.test(passwd)
            }

            export function is(passwd: unknown): passwd is Salted {
                if (typeof passwd !== 'string') return false
                return IS_IMPL(passwd)
            }
        }

        export type Salted = `${Salt}${string}`
    }

    export type Password = string

    export namespace Salt {
        export const Regex = /^\$2[abxy]\$((0[4-9])|([1-2][0-9])|30|31)\$[./A-Za-z0-9]{22}$/

        export function IS_IMPL(salt: string): salt is Salt {
            return Salt.Regex.test(salt)
        }

        export function is(salt: unknown): salt is Salt {
            return typeof salt === 'string' && IS_IMPL(salt)
        }
    }

    export type Salt = `$2${'a' | 'b' | 'x' | 'y'}${`0${Range<4, 9>}` | Range<10, 32>}`

    export const Metadata = _Metadata
    export type Metadata = _Metadata

    export namespace Data {
        export type Partial = { id: Data['id'] } & { [K in keyof Omit<Data, 'id'>]?: Data[K] }
    }

    export interface Data extends _Metadata.With {
        id: ID
        email?: Email

        name?: Name
        image?: Image

        passwd?: Password
        salt?: Salt

        role: Some<Role>
        locale: Locale
        parent?: User
    }

    export namespace JSONSchema {
        export const Ref = 'user' as const
        export type Ref = typeof Ref

        export const Schema = {
            $id: Ref,

            type: _JSONSchema.Type.Object,
            required: ['id', 'role', 'locale'],
            properties: {
                id: {
                    type: _JSONSchema.Type.String,
                    pattern: ID.Regex.source,
                    description: 'must be a valid user ID',
                },

                name: {
                    type: _JSONSchema.Type.String,
                    minLength: Name.Length.Min,
                    maxLength: Name.Length.Max,
                },

                passwd: true,
                salt: true,

                locale: {
                    type: _JSONSchema.Type.String,
                    enum: Locale.All,
                    description: `must be a valid BCP47 tag of ${LTILocale.All.reduce(
                        (acc, x, i) =>
                            `${acc}'${x}'${i === LTILocale.All.length - 1 ? '' : i === LTILocale.All.length - 2 ? ' and ' : ', '
                            }`,
                        ''
                    )}`,
                },

                role: {
                    anyOf: [
                        {
                            type: _JSONSchema.Type.Array,
                            minItems: 1,
                            uniqueItems: true,
                            items: {
                                type: _JSONSchema.Type.String,
                                enum: Role.All,
                                description: `must be a valid LTI role of ${Role.All.reduce(
                                    (acc, x, i) =>
                                        `${acc}'${x}'${i === Role.All.length - 1 ? '' : i === Role.All.length - 2 ? ' and ' : ', '
                                        }`,
                                    ''
                                )}`,
                            },
                        },
                        {
                            type: _JSONSchema.Type.String,
                            enum: Role.All,
                            description: `must be a valid LTI role of ${Role.All.reduce(
                                (acc, x, i) =>
                                    `${acc}'${x}'${i === Role.All.length - 1 ? '' : i === Role.All.length - 2 ? ' and ' : ', '
                                    }`,
                                ''
                            )}`,
                        },
                    ],
                },

                parent: {
                    anyOf: [
                        {
                            type: _JSONSchema.Type.String,
                            pattern: ID.Regex.source,
                            description: 'must be a valid user ID',
                        },
                        { $ref: Ref },
                    ],
                },

                metadata: { $ref: Metadata.Ref },
            },

            oneOf: [
                {
                    properties: {
                        role: {
                            anyOf: [
                                { type: _JSONSchema.Type.String, const: Role.Student },
                                {
                                    type: _JSONSchema.Type.Array,
                                    minItems: 1,
                                    uniqueItems: true,
                                    items: {
                                        type: _JSONSchema.Type.String,
                                        const: Role.Student,
                                    },
                                },
                            ],
                        },
                    },

                    not: { required: ['email'] },
                },

                {
                    properties: {
                        role: {
                            anyOf: [
                                {
                                    type: _JSONSchema.Type.String,
                                    enum: Role.All.filter(x => x !== Role.Student),
                                },
                                {
                                    type: _JSONSchema.Type.Array,
                                    minItems: 1,
                                    uniqueItems: true,
                                    items: {
                                        type: _JSONSchema.Type.String,
                                        enum: Role.All.filter(x => x !== Role.Student),
                                    },
                                },
                            ],
                        },

                        email: {
                            type: _JSONSchema.Type.String,
                            pattern: Email.Regex.Global.source,
                            description: 'must be a valid email address',
                        },
                    },
                },
            ],

            anyOf: [
                {
                    properties: {
                        passwd: {
                            type: _JSONSchema.Type.String,
                            description: 'Password must be a string.',
                        },
                    },

                    not: { required: ['salt'] },
                },
                {
                    properties: {
                        passwd: {
                            type: _JSONSchema.Type.String,
                            pattern: Password.Salted.Regex.source,
                            description: 'Password must be a valid bcrypt hash.',
                        },

                        salt: {
                            type: _JSONSchema.Type.String,
                            pattern: Salt.Regex.source,
                            description: 'Salt must be a valid bcrypt salt.',
                        },
                    },
                },
            ],
        } as const satisfies Schema

        export type Schema = _JSONSchema<Data>
    }

    export type JSONSchema = JSONSchema.Schema

    export const { Ref } = JSONSchema
    export type Ref = JSONSchema.Ref

    export const { Schema } = JSONSchema
    export type Schema = JSONSchema.Schema

    export namespace Zod {
        const base = z
            .object({
                id: z.string().regex(ID.Regex),
                email: z.string().regex(Email.Regex.Global).optional(),

                name: z.string().trim().min(Name.Length.Min).max(Name.Length.Max).optional(),
                image: z
                    .union([
                        z.instanceof(globalThis.File).refine(Image.Blob.IS_IMPL),
                        z.instanceof(globalThis.Blob).refine(Image.Blob.IS_IMPL),
                        z.string().refine(Image.DataURL.IS_IMPL),
                    ])
                    .optional(),

                passwd: z.string().optional(),
                salt: z.string().regex(Salt.Regex).optional(),

                locale: z.enum(Locale.All),
                role: z.enum(Role.All),
            })
            .refine(({ passwd, salt }) => {
                if (salt === undefined) {
                    if (passwd === undefined) return true
                    return Password.IS_IMPL(passwd)
                }

                if (passwd === undefined) return false
                return Password.Salted.IS_IMPL(passwd)
            }) satisfies z.ZodType<
                Omit<Data, 'email' | 'image' | 'salt'> & { email?: string; image?: string | Blob | File; salt?: string }
            >

        export const Schema = base._def.schema
            .extend({
                parent: z.union([base._def.schema.shape.id, z.lazy(() => base)]).optional(),
            })
            .strict()

        export type Schema = z.ZodType<Data>
    }

    export const ZodSchema = Zod.Schema
    export type ZodSchema = Zod.Schema

    export function normalize(user: Omit<Data, 'id'> & ({ id: ID } | { _id: ID })): Data & { id: string }
    export function normalize(
        user: Omit<Partial<Data>, 'id'> & ({ id: ID } | { _id: ID })
    ): Partial<Data> & { id: string }
    export function normalize(
        user: (Omit<Data, 'id'> & ({ id: ID } | { _id: ID })) | null | undefined
    ): (Data & { id: string }) | undefined
    export function normalize(
        user: (Omit<Partial<Data>, 'id'> & ({ id: ID } | { _id: ID })) | null | undefined
    ): (Partial<Data> & { id: string }) | undefined
    export function normalize(user: Partial<Data> & ({ id?: ID } | { _id?: ID })): Partial<Data> & { id?: string }
    export function normalize(
        user: (Partial<Data> & ({ id?: ID } | { _id?: ID })) | null | undefined
    ): (Partial<Data> & { id?: string }) | undefined
    export function normalize(user: ID | (Omit<Data, 'id'> & ({ id: ID } | { _id: ID }))): ID | (Data & { id: string })
    export function normalize(
        user: ID | (Omit<Partial<Data>, 'id'> & ({ id: ID } | { _id: ID }))
    ): ID | (Partial<Data> & { id: string })
    export function normalize(
        user: ID | (Omit<Data, 'id'> & ({ id: ID } | { _id: ID })) | undefined | null
    ): ID | (Partial<Data> & { id: string }) | undefined
    export function normalize(
        user: ID | (Omit<Partial<Data>, 'id'> & ({ id: ID } | { _id: ID })) | undefined | null
    ): ID | (Omit<Partial<Data>, 'id'> & ({ id: ID } | { _id: ID })) | undefined
    export function normalize(user: ID): string
    export function normalize(user: ID | undefined | null): string | undefined
    export function normalize(user: Data): Data
    export function normalize(user: Data | undefined | null): Data | undefined
    export function normalize(user: Partial<User>): Partial<User>
    export function normalize(user: Partial<User> | null | undefined): Partial<User> | undefined
    export function normalize(user: User): User
    export function normalize(user: User | null | undefined): User | undefined
    export function normalize(user: undefined | null): undefined

    export function normalize(user: unknown): unknown {
        if (user === undefined || user === null) return user
        if (ID.is(user)) return user
        if (typeof user !== 'object') return user

        const id = User.ID.get(user)

        let { email, role, locale, parent, metadata } = user as Data

        if (!Locale.is(locale)) locale = Locale.English
        email = Email.get(email, undefined, locale)
        role = Role.get(role)
        parent = normalize(parent) as typeof parent
        metadata = Metadata.normalize(metadata)

        function undef(x: unknown): unknown {
            if (!(x && typeof x === 'object')) return x

            if (Array.isArray(x)) return x.map(undef)

            return Object.fromEntries(
                Object.entries(x)
                    .filter(([_, v]) => !(v === null || typeof v === 'undefined'))
                    .map(([k, v]) => [k, undef(v)])
            )
        }

        const ret = undef({ ...user, id, email, role, locale, parent, metadata })
        delete (ret as { _id?: ID })._id

        const entries = Object.entries(ret as Data)
        entries.sort(([ka, _], [kb, __]) => {
            const KEYS = [
                'id',

                'name',
                'image',

                'email',
                'role',

                'passwd',
                'salt',

                'locale',
                'parent',
                'metadata',
            ] as const

            const ia = KEYS.indexOf(ka as (typeof KEYS)[number])
            const ib = KEYS.indexOf(kb as (typeof KEYS)[number])

            if (ia < 0) {
                if (ib < 0) return 0
                return 1
            }

            if (ib < 0) return -1

            return ia - ib
        })

        return Object.fromEntries(entries)
    }

    let Validator!: Ajv

    export function is(
        user: unknown,
        id?: false,
        partial?: boolean,
        errors?: Parameters<typeof PushErrors>[0]
    ): user is typeof partial extends true | undefined ? Partial<Data> : Data
    export function is(user: unknown, id: 'id'): user is ID
    export function is(
        user: unknown,
        id: true,
        partial?: boolean,
        errors?: Parameters<typeof PushErrors>[0]
    ): user is typeof partial extends true | undefined ? Partial<User> : User

    export function is(
        user: unknown,
        id: boolean | undefined | 'id' = false,
        partial: boolean | undefined = true,
        errors?: Parameters<typeof PushErrors>[0]
    ) {
        if (!Validator) {
            Validator = new Ajv({
                allErrors: true,
                verbose: true,
            })

            ajv_errors(Validator)
            addFormats(Validator)
        }

        for (const schema of [Metadata.Schema, Schema] as const) {
            if (!Validator.getSchema(schema.$id))
                try {
                    Validator.addSchema([schema, Partialize(schema)].map(AddErrorMessages))
                } catch { }
        }

        id ??= false
        partial ??= true

        if (id && ID.is(user)) return true
        if (id === 'id') return false

        let { $id }: { $id: string } = Schema
        if (partial) $id = `${$id}_partial`

        if (errors) {
            const validate = Validator.compile({ $ref: $id })
            if (validate(user)) {
                const { passwd, salt } = user as Data

                if (passwd === undefined) return true
                if (salt !== undefined) return true
                return Password.IS_IMPL(passwd)
            }

            PushErrors(errors, validate.errors)
            return false
        }

        if (Validator.validate($id, user)) return false
        const { passwd, salt } = user as Data

        if (passwd === undefined) return true
        if (salt !== undefined) return true
        return Password.IS_IMPL(passwd)
    }
}

export const { ID } = User
export type ID = User.ID

export const { Name } = User
export type Name = User.Name

export const { Image } = User
export type Image = User.Image

export const { is, normalize } = User

export const { Email } = User
export type Email = User.Email

export const { Password } = User
export type Password = User.Password

export const { Salt } = User
export type Salt = User.Salt

export const { Role } = User
export type Role = User.Role

export const { Locale } = User
export type Locale = User.Locale

export const { JSONSchema } = User
export type JSONSchema = User.JSONSchema

export const { Schema } = User
export type Schema = User.Schema

export const { Ref } = User
export type Ref = User.Ref

export const { Zod } = User

export const { ZodSchema } = User
export type ZodSchema = User.ZodSchema

export namespace Parent {
    export const { ID } = User
    export type ID = User.ID

    export type Data = User.Data

    export const { Ref } = User
    export type Ref = User.Ref

    export const { Schema } = User
    export type Schema = User.Schema
}
export type Parent = User

export const { Metadata } = User
export type Metadata = User

export namespace Data {
    export type Partial = User.Data.Partial
}
export type Data = User.Data

type User = ID | Data

export default User
