import Natural from '@repo/types/number/Natural'
import _JSONSchema, { Partialize, AddErrorMessages, PushErrors } from '@repo/types/schema/JSONSchema'
import _Hash from '@repo/types/util/Hash'
import OID from '@repo/types/util/id/OID'
import UserID from '@repo/types/util/id/UserID'
import Ajv from 'ajv'
import ajv_errors from 'ajv-errors'
import addFormats from 'ajv-formats'
import { Int32, Long } from 'bson'
import { z } from 'zod'

namespace Metadata {
    export const MAX_DATE = 8_640_000_000_000_000 as const
    export type MAX_DATE = typeof MAX_DATE

    export function Hash(data: Parameters<typeof _Hash>[0]): Hash {
        return _Hash(data, Hash.Algorithm)
    }

    export namespace Hash {
        export const Algorithm = _Hash.Algorithm.SHA.SHA3.SHA3_224.Name
        export type Algorithm = _Hash.Algorithm.SHA.SHA3.SHA3_224

        export const { Length } = _Hash.Algorithm.SHA.SHA3.SHA3_224
        export type Length = _Hash.Algorithm.SHA.SHA3.SHA3_224.Length

        export function is(hash: number): false
        export function is(hash: bigint): false
        export function is(hash: object): false
        export function is(hash: null): false
        export function is(hash: undefined): false
        export function is(hash: string): boolean
        export function is(hash: Hash): true
        export function is(hash: unknown): boolean
        export function is(hash: unknown): hash is Hash {
            return _Hash.is(hash, Algorithm)
        }

        export function get(data: With | null | undefined): Hash | undefined
        export function get(metadata: Omit<Metadata, 'hash'> & { hash: Hash }): Hash | undefined
        export function get(metadata: Metadata): Hash | undefined
        export function get(metadata: Metadata | undefined): Hash | undefined
        export function get(hash: Hash): Hash
        export function get(hash: Hash | undefined): Hash | undefined
        export function get(data: unknown): Hash | undefined {
            if (data === undefined) return undefined
            if (data === null) return undefined

            if (typeof data === 'string') {
                if (is(data)) return data
                return undefined
            }

            if (typeof data !== 'object') return undefined
            if ('metadata' in data) data = data.metadata
            if (typeof data === 'object' && data && 'hash' in data) {
                if (is(data.hash)) return data.hash as Hash
                return undefined
            }

            return undefined
        }
    }

    export type Hash = _Hash<Hash.Algorithm>

    export namespace Size {
        export function get(data: unknown, full = false) {
            if (typeof data === 'undefined' || data === null) return 0

            if (typeof data !== 'object')
                return new TextEncoder().encode(JSON.stringify(data).normalize('NFD')).byteLength

            data = structuredClone(data)
            if (typeof data !== 'object' || data === null) return 0

            if ('metadata' in data)
                // biome-ignore lint/performance/noDelete:
                delete data.metadata

            let sub = 0
            for (let [k, v] of Object.entries(data)) {
                if (!(k in data)) continue

                if (v === undefined) {
                    delete data[k as keyof typeof data]
                    continue
                }

                switch (k) {
                    case 'doc':
                    case 'from':
                    case 'choices':
                    case 'questions':
                    case 'activities':
                    case 'phases':
                    case 'users':
                    case 'teachers':
                    case 'admins':
                    case 'groups':
                        break

                    default:
                        continue
                }

                if (k !== 'doc' && k !== 'from') v = Array.isArray(v) ? v : [v]

                if (!full) {
                    if ((k === 'doc' || k === 'from') && 'type' in data) {
                        if (data.type === 'user') v = UserID.get(v)
                        else if (
                            data.type === 'choice' ||
                            data.type === 'activity' ||
                            data.type === 'phase' ||
                            data.type === 'scenario' ||
                            data.type === 'group' ||
                            data.type === 'institution'
                        )
                            v = OID.get(v)
                    }

                    if (k === 'users' || k === 'teachers' || k === 'admins') {
                        if (Array.isArray(v)) v = v.map(UserID.get)
                        else v = UserID.get(v)
                    } else {
                        if (Array.isArray(v)) v = v.map(OID.get)
                        else v = OID.get(v)
                    }
                    ;(data as Record<typeof k, unknown>)[k] = v

                    continue
                }

                if (k in data) delete data[k as keyof typeof data]

                if (v) sub += v.reduce((acc: number, x: Record<string, unknown>) => acc + get(x, true), 0)
            }

            return new TextEncoder().encode(JSON.stringify(data).normalize('NFD')).byteLength + sub
        }

        export function normalize(size: Size): Natural
        export function normalize(size: undefined): undefined
        export function normalize(size: Size | undefined): Natural | undefined
        export function normalize(size: Size | undefined): Natural | undefined {
            switch (true) {
                case size instanceof Int32: {
                    size = size.toJSON()
                    if (!Natural.is(size)) {
                        size = undefined
                    }

                    break
                }

                case Long.isLong(size): {
                    size = size.toExtendedJSON({ useBigInt64: true }) as number
                    if (!Natural.is(size)) {
                        size = undefined
                    }

                    break
                }

                case typeof size === 'bigint': {
                    size = Number(size)
                    if (!Natural.is(size)) {
                        size = undefined
                    }

                    break
                }

                case typeof size === 'number':
                    if (!Natural.is(size)) {
                        size = undefined
                    }
            }

            if (size && (size as Natural) > Number.MAX_SAFE_INTEGER) {
                size = Number.MAX_SAFE_INTEGER
            }

            return size as Natural | undefined
        }
    }

    export type Size = bigint | Natural | Int32 | Long

    export namespace CreatedOn {
        export function normalize(created_on: CreatedOn): Natural
        export function normalize(created_on: undefined): undefined
        export function normalize(created_on: CreatedOn | undefined): Natural | undefined
        export function normalize(created_on: CreatedOn | undefined): Natural | undefined {
            switch (true) {
                case created_on instanceof Int32: {
                    created_on = created_on.toJSON()
                    if (!Natural.is(created_on)) {
                        created_on = undefined
                    }

                    break
                }

                case Long.isLong(created_on): {
                    created_on = created_on.toExtendedJSON({
                        useBigInt64: true,
                    }) as number
                    if (!Natural.is(created_on)) {
                        created_on = undefined
                    }

                    break
                }

                case created_on instanceof Date: {
                    created_on = created_on.getTime()

                    break
                }

                case typeof created_on === 'string': {
                    created_on = new Date(created_on)?.getTime()
                    if (Number.isNaN(created_on.valueOf())) {
                        created_on = undefined
                    }

                    break
                }

                case typeof created_on === 'bigint': {
                    created_on = Number(created_on)
                    if (!Natural.is(created_on)) {
                        created_on = undefined
                    }

                    break
                }

                case typeof created_on === 'number':
                    if (!Natural.is(created_on)) {
                        created_on = undefined
                    }
            }

            if (created_on && (created_on as Natural) > MAX_DATE) {
                created_on = MAX_DATE
            }

            return created_on as Natural | undefined
        }
    }

    export type CreatedOn = bigint | Natural | Int32 | Long | Date | string

    export namespace CreatedBy {
        export function normalize(created_by: CreatedBy): UserID
        export function normalize(created_by: undefined): undefined
        export function normalize(created_by: CreatedBy | undefined): UserID | undefined
        export function normalize(created_by: CreatedBy | undefined): UserID | undefined {
            return UserID.get(created_by)
        }
    }

    export type CreatedBy =
        | UserID
        | { id: UserID; [x: string | number | symbol]: unknown }
        | { _id: UserID; [x: string | number | symbol]: unknown }

    export namespace UpdatedOn {
        export function normalize(updated_on: UpdatedOn): Natural
        export function normalize(updated_on: undefined): undefined
        export function normalize(updated_on: UpdatedOn | undefined): Natural | undefined
        export function normalize(updated_on: UpdatedOn | undefined): Natural | undefined {
            return CreatedOn.normalize(updated_on)
        }
    }

    export type UpdatedOn = CreatedOn

    export namespace UpdatedBy {
        export function normalize(updated_by: UpdatedBy): UserID
        export function normalize(updated_by: undefined): undefined
        export function normalize(updated_by: UpdatedBy | undefined): UserID | undefined
        export function normalize(updated_by: UpdatedBy | undefined): UserID | undefined {
            return CreatedBy.normalize(updated_by)
        }
    }

    export type UpdatedBy = CreatedBy

    export interface With {
        metadata?: Metadata
    }

    export namespace JSONSchema {
        export const Ref = 'metadata' as const
        export type Ref = typeof Ref

        export const Schema = {
            $id: Ref,

            type: _JSONSchema.Type.Object,
            additionalProperties: false,

            properties: {
                created_on: {
                    anyOf: [
                        {
                            type: _JSONSchema.Type.Integer,
                            minimum: 0,
                            description: 'must be a natural number',
                        },
                        {
                            type: _JSONSchema.Type.String,
                            format: _JSONSchema.Format.DateTime.DateTime,
                            description: 'must be a date',
                        },
                    ],
                },

                created_by: {
                    type: _JSONSchema.Type.String,
                    pattern: UserID.Regex.source,
                    description: 'must be a valid user ID',
                },

                updated_on: {
                    anyOf: [
                        {
                            type: _JSONSchema.Type.Integer,
                            minimum: 0,
                            description: 'must be a natural number',
                        },
                        {
                            type: _JSONSchema.Type.String,
                            format: _JSONSchema.Format.DateTime.DateTime,
                            description: 'must be a date',
                        },
                    ],
                },

                updated_by: {
                    type: _JSONSchema.Type.String,
                    pattern: UserID.Regex.source,
                    description: 'must be a valid user ID',
                },

                size: {
                    type: _JSONSchema.Type.Integer,
                    minimum: 0,
                    description: 'must be a natural number',
                },

                hash: {
                    type: _JSONSchema.Type.String,
                    pattern: _Hash.Algorithm.SHA.SHA3.SHA3_224.Regex.source,
                    description: 'must be a valid SHA3-224 hash',
                },
            },
        } as const satisfies Schema

        export type Schema = _JSONSchema<Metadata>
    }

    export const { Ref } = JSONSchema
    export type Ref = JSONSchema.Ref

    export const { Schema } = JSONSchema
    export type Schema = JSONSchema.Schema

    export namespace Zod {
        const created_on = z.union([z.number().int().min(0), z.string().datetime()])
        const created_by = z.string().regex(UserID.Regex)

        export const Schema = z.object({
            created_on,
            updated_on: created_on,

            created_by,
            updated_by: created_by,

            size: z.number().int().min(0),

            hash: z.string().regex(_Hash.Algorithm.SHA.SHA3.SHA3_224.Regex),
        }) satisfies Schema

        export type Schema = z.ZodType<Metadata>
    }

    export const ZodSchema = Zod.Schema
    export type ZodSchema = Zod.Schema

    let Validator!: Ajv

    export function is(metadata: unknown, errors: Parameters<typeof PushErrors>[0] = undefined): metadata is Metadata {
        if (!Validator) {
            Validator = new Ajv({
                allErrors: true,
                verbose: true,
            })

            ajv_errors(Validator)
            addFormats(Validator)

            Validator.addSchema([Schema].flatMap(x => [x, Partialize(x)]).map(AddErrorMessages))
        }

        if (errors === undefined) {
            return Validator.validate(Ref, metadata)
        }

        const validate = Validator.compile({ $ref: Ref })

        if (!validate(metadata)) {
            PushErrors(errors, validate.errors)
            return false
        }

        return true
    }

    export function normalize(metadata: Metadata): {
        created_on?: ReturnType<typeof CreatedOn.normalize>
        created_by?: ReturnType<typeof CreatedBy.normalize>

        updated_on?: ReturnType<typeof UpdatedOn.normalize>
        updated_by?: ReturnType<typeof UpdatedBy.normalize>

        hash?: Hash
        size?: ReturnType<typeof Size.normalize>
    }
    export function normalize(metadata: null | undefined): undefined
    export function normalize(metadata: Metadata | null | undefined):
        | {
              created_on?: ReturnType<typeof CreatedOn.normalize>
              created_by?: ReturnType<typeof CreatedBy.normalize>

              updated_on?: ReturnType<typeof UpdatedOn.normalize>
              updated_by?: ReturnType<typeof UpdatedBy.normalize>

              hash?: Hash
              size?: ReturnType<typeof Size.normalize>
          }
        | undefined
    export function normalize(metadata: Metadata | null | undefined):
        | {
              created_on?: ReturnType<typeof CreatedOn.normalize>
              created_by?: ReturnType<typeof CreatedBy.normalize>

              updated_on?: ReturnType<typeof UpdatedOn.normalize>
              updated_by?: ReturnType<typeof UpdatedBy.normalize>

              hash?: Hash
              size?: ReturnType<typeof Size.normalize>
          }
        | undefined {
        if (!metadata) {
            return undefined
        }

        let { created_on, created_by, updated_on, updated_by, size, hash } = metadata
        created_on = CreatedOn.normalize(created_on)
        created_by = CreatedBy.normalize(created_by)

        updated_on = UpdatedOn.normalize(updated_on)
        updated_by = UpdatedBy.normalize(created_by)

        size = Size.normalize(size)

        const ret = { created_on, created_by, updated_on, updated_by, size, hash }

        for (const key in ret) {
            if (ret[key as keyof typeof ret] === undefined) {
                delete ret[key as keyof typeof ret]
            }
        }

        return ret
    }

    export function create<T>(
        data: T & With,
        { created_on, created_by }: { created_on?: CreatedOn; created_by?: CreatedBy | { id: UserID } }
    ) {
        const size = Size.get(data)
        const hash = Hash(data)
        created_by = UserID.get(created_by)

        return {
            ...data,
            metadata: normalize({
                size,
                created_on,
                created_by,
                hash,
            }),
        }
    }

    export function update<T>(
        data: T & With,
        { updated_on, updated_by }: { updated_on?: UpdatedOn; updated_by?: UpdatedBy | { id: UserID } }
    ) {
        let { metadata } = data
        if (!metadata) metadata = {}

        let { created_on, created_by } = metadata

        if (!updated_on) updated_on = Date.now()
        if (!data.metadata) {
            if (created_on === undefined) created_on = updated_on
            if (created_by === undefined) created_by = updated_by
        }

        const size = Size.get(data)
        const hash = Hash(data)

        created_by = UserID.get(created_by)
        updated_by = UserID.get(updated_by)

        return {
            ...data,
            metadata: normalize({
                size,
                created_on,
                created_by,
                updated_on,
                updated_by,
                hash,
            }),
        }
    }
}

interface Metadata {
    hash?: Metadata.Hash
    size?: Metadata.Size

    created_on?: Metadata.CreatedOn
    created_by?: Metadata.CreatedBy

    updated_on?: Metadata.UpdatedOn
    updated_by?: Metadata.UpdatedBy
}

export const { Hash } = Metadata
export type Hash = Metadata.Hash

export const { Size } = Metadata
export type Size = Metadata.Size

export const { CreatedOn } = Metadata
export type CreatedOn = Metadata.CreatedOn

export const { CreatedBy } = Metadata
export type CreatedBy = Metadata.CreatedBy

export const { UpdatedOn } = Metadata
export type UpdatedOn = Metadata.UpdatedOn

export const { UpdatedBy } = Metadata
export type UpdatedBy = Metadata.UpdatedBy

export default Metadata
