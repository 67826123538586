import BCP47 from '@repo/types/locale/BCP47'

/* istanbul ignore next */
namespace ISO3166_1 {
    export const France = 'FR' as const
    export type France = typeof France

    export const Germany = 'DE' as const
    export type Germany = typeof Germany

    export const Greece = 'GR' as const
    export type Greece = typeof Greece

    export const Portugal = 'PT' as const
    export type Portugal = typeof Portugal

    export const Spain = 'ES' as const
    export type Spain = typeof Spain

    export const UK = 'GB' as const
    export type UK = typeof UK

    export function is(code: unknown): code is ISO3166_1 {
        switch (code) {
            case France:
            case Germany:
            case Greece:
            case Portugal:
            case Spain:
            case UK:
                return true
            default:
                return false
        }
    }

    export function Native(locale: ISO3166_1.France): Native.France
    export function Native(locale: ISO3166_1.Germany): Native.Germany
    export function Native(locale: ISO3166_1.Greece): Native.Greece
    export function Native(locale: ISO3166_1.Portugal): Native.Portugal
    export function Native(locale: ISO3166_1.Spain): Native.Spain
    export function Native(locale: ISO3166_1.UK): Native.UK
    export function Native(locale: ISO3166_1): Native
    export function Native(locale: unknown): undefined
    export function Native(locale: unknown): Native | undefined {
        switch (locale) {
            case France:
                return Native.France
            case Germany:
                return Native.Germany
            case Greece:
                return Native.Greece
            case Portugal:
                return Native.Portugal
            case Spain:
                return Native.Spain
            case UK:
                return Native.UK
            default:
                return undefined
        }
    }

    /* istanbul ignore next */
    export namespace Native {
        export const France = 'France' as const
        export type France = typeof France

        export const Germany = 'Deutschland' as const
        export type Germany = typeof Germany

        export const Greece = 'Ελλάδα' as const
        export type Greece = typeof Greece

        export const Portugal = 'Portugal' as const
        export type Portugal = typeof Portugal

        export const Spain = 'España' as const
        export type Spain = typeof Spain

        export const UK = 'English' as const
        export type UK = typeof UK

        export function is(name: unknown): name is Native {
            switch (name) {
                case UK:
                case Germany:
                case France:
                case Greece:
                case Spain:
                case Portugal:
                    return true
                default:
                    return false
            }
        }
    }

    // biome-ignore lint/suspicious/noRedeclare:
    export type Native = (typeof Native)[Exclude<keyof typeof Native, 'is'>]

    export function Locale(code: ISO3166_1.France): BCP47.French
    export function Locale(code: ISO3166_1.Germany): BCP47.German
    export function Locale(code: ISO3166_1.Greece): BCP47.Greek
    export function Locale(code: ISO3166_1.Portugal): BCP47.Portuguese
    export function Locale(code: ISO3166_1.Spain): BCP47.Spanish
    export function Locale(code: ISO3166_1.UK): BCP47.English
    export function Locale(code: ISO3166_1): BCP47
    export function Locale(code: unknown): undefined
    export function Locale(code: unknown): BCP47 | undefined {
        switch (code) {
            case France:
                return Locale.France
            case Germany:
                return Locale.Germany
            case Greece:
                return Locale.Greece
            case Portugal:
                return Locale.Portugal
            case Spain:
                return Locale.Spain
            case UK:
                return Locale.UK
            default:
                return undefined
        }
    }

    /* istanbul ignore next */
    export namespace Locale {
        export const France = BCP47.French
        export type France = BCP47.French

        export const Germany = BCP47.German
        export type Germany = BCP47.German

        export const Greece = BCP47.Greek
        export type Greece = BCP47.Greek

        export const Portugal = BCP47.Portuguese
        export type Portugal = BCP47.Portuguese

        export const Spain = BCP47.Spanish
        export type Spain = BCP47.Spanish

        export const UK = BCP47.English
        export type UK = BCP47.English
    }

    // biome-ignore lint/suspicious/noRedeclare: <explanation>
    export type Locale = (typeof Locale)[keyof typeof Locale] extends BCP47
        ? (typeof Locale)[keyof typeof Locale]
        : never
}

type ISO3166_1 = (typeof ISO3166_1)[Exclude<keyof typeof ISO3166_1, 'is' | 'Native' | 'Locale'>]

export const ISO3166_1Native = ISO3166_1.Native
export type ISO3166_1Native = (typeof ISO3166_1Native)[keyof typeof ISO3166_1Native]

export default ISO3166_1
